<template>
  <div>
    <div v-if="Object.keys(room_booking).length !== 0">
      <div class="row">
        <div class="col-md-12 mb-2">
          <a-button :loading="btnAdminApproved" v-if="room_booking.room_booking_status.includes('ADMIN_PENDING') && !room_booking.room_booking_status.includes('ADMIN_APPROVED') && !room_booking.room_booking_status.includes('ADMIN_REJECTED')" class="btn btn-success btn-sm mr-2 float-right" @click.prevent="adminApprovedStatus(room_booking.id)"><i class="fa fa-check"></i> Approve</a-button>
          <a-button :loading="btnAdminReject" v-if="room_booking.room_booking_status.includes('ADMIN_PENDING') && !room_booking.room_booking_status.includes('ADMIN_APPROVED') && !room_booking.room_booking_status.includes('ADMIN_REJECTED')" class="btn btn-danger btn-sm mr-2 float-right" @click.prevent="adminRejectStatus(room_booking.id)"><i class="fa fa-times"></i> Reject</a-button>
        </div>
      </div>
      <room-booking-header-status :current="current" :room_booking="room_booking"></room-booking-header-status>
      <room-booking-time-slots :room_booking="room_booking"></room-booking-time-slots>
      <div class="col-md-12">
        <h5>Employee Details</h5>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">Room Booking No.: {{ room_booking.room_booking_no }}</div>
              <div class="col-md-4">Purpose of meeting: {{ room_booking.meeting_purpose }}</div>
              <div class="col-md-4">Booking Date: {{ room_booking.booking_date }}</div>
              <div class="col-md-4">Name: {{ room_booking.name }}</div>
              <div class="col-md-4">Department: {{ room_booking.department.department_name }}</div>
              <div class="col-md-4">Job ID: {{ room_booking.job_id }}</div>
              <div class="col-md-4">Phone No.: {{ room_booking.phone_no }}</div>
              <div class="col-md-4">Email: {{ room_booking.email }}</div>
              <div class="col-md-4">Manager`s Email: {{ room_booking.managers_email }}</div>
              <div class="col-md-4">No. of participants : {{ room_booking.no_if_person }}</div>
              <div class="col-md-4">Other Requirements: {{ room_booking.other_requirements }}</div>
            </div>
          </div>
        </div>
      </div>
      <room-booking-additional-facilities v-if="room_booking.room_booking_additional_facilities" :room_booking="room_booking"></room-booking-additional-facilities>
      <room-booking-entertainments v-if="room_booking.room_booking_entertainments" :props_room_booking="room_booking" @getRoomBooking="getRoomBooking"></room-booking-entertainments>
    </div>
<!--    <div v-else>-->
<!--      <h5 class="text-center text-secondary">-->
<!--        <Empty :image="simpleImage"></Empty>-->
<!--      </h5>-->
<!--    </div>-->
    <a-skeleton active :loading="loading"></a-skeleton>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import roomBookingAdditionalFacilities from '@/views/roomBooking/admin/partial/room_booking_additional_facilities'
import roomBookingEntertainments from '@/views/roomBooking/admin/partial/room_booking_entertainments'
import roomBookingTimeSlots from '@/views/roomBooking/admin/partial/room_booking_time_slots'
import roomBookingHeaderStatus from '@/views/roomBooking/admin/partial/room_booking_header_status'

export default {
  name: 'List',
  components: { roomBookingAdditionalFacilities, roomBookingEntertainments, roomBookingTimeSlots, roomBookingHeaderStatus },
  data() {
    return {
      room_booking: {},
      loading: false,
      spinning: false,
      delayTime: 500,
      current: 0,
      btnAdminReject: false,
      btnAdminApproved: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getRoomBooking()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getRoomBooking() {
      const roomBookingId = this.$route.params.room_booking_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/admin/room-booking/view/' + roomBookingId)
        .then(response => {
          this.loading = false
          this.spinning = false
          if (!response.data.error) {
            const data = response.data
            this.room_booking = data.room_booking
            this.current = data.max_room_booking_status
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    adminRejectStatus(roomBookingId, index) {
      if (confirm('Do you really want to reject?')) {
        this.btnAdminReject = true
        apiClient.post('api/admin/room-booking/reject', { roomBookingId: roomBookingId }).then(response => {
          this.btnAdminReject = false
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.getRoomBooking()
            }
          } else {
            this.$notification.error({
              message: 'Room Booking Rejected',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    adminApprovedStatus(roomBookingId, index) {
      if (confirm('Do you really want to approved?')) {
        this.btnAdminApproved = true
        apiClient.post('api/admin/room-booking/approved', { roomBookingId: roomBookingId }).then(response => {
          this.btnAdminApproved = false
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.getRoomBooking()
            }
          } else {
            this.$notification.error({
              message: 'Room Booking Approved',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
</style>
